export default {
  'advantage-of-a-setting-sun': {
    slug: 'advantage-of-a-setting-sun',
    title: 'Exhibition: Advantage of a Setting Sun',
    date: '15-07-2021',
    location: 'Bibliotheca, London',
    image: 'https://storage.googleapis.com/bibliotheca-assets/aoass-biblioteka.jpg',
    description: `
        Opening: 15th July 2021, 6-10pm \n
        Facebook event here: <a href="https://fb.me/e/1vZe39orz">https://fb.me/e/1vZe39orz</a> \n
        The exhibition combines a video installation, sculpture and a collection of texts that converge in the late 19th century discovery of Liquid Crystals; a state of matter which is neither solid nor liquid. This new state of matter provided soil for new technologies and new directions in the western cultural imagination and remains a pervasive material presence and cultural influence today. The work weaves a narrative from the history of soap and surfactants, flat screen displays and the advent of modern advertising and public relations. \n
        Jazbo Gross is a London based artist who works in video and installation. His work deals with material histories of image display technology at their intersection with contemporary forms of storytelling. He holds an MA in Moving Image from the Royal College of Art (2017) and has exhibited and screened his work in the UK, Europe, Asia and the USA. \n\n
        Entry: Free
        PV
        Thursday 15th July, 6pm - 10pm

        Open
        16th to 22nd July, 12pm - 8pm
        23rd to 24th July, Open by appointment
      `
  },
  'metaculture-reading-room': {
    slug: 'metaculture-reading-room',
    title: 'Reading Room: Metaculture',
    date: '14-03-2019',
    location: 'Kyiv, Nyzhnoiurkivska St, 31',
    description: `
          Bibliotheca is opening its new reading room in collaboration with Metaculture. 
          Recently acquired books will be on display. 
          We are also going to play some tunes on our amazing stereo system. 

          More to come in the following months. Stay tuned.
          \n
          <a href="http://www.facebook.com/events/336892086942858/">http://www.facebook.com/events/336892086942858/</a>
          Starts @ 5pm
          Free admission
        `,
    image: 'https://storage.googleapis.com/bibliotheca-assets/metaculture-reading-room-cover.jpg',
  },
  'bibliotheca-24': {
    slug: 'bibliotheca-24',
    title: 'БІБЛІОТЕКА-24',
    date: '07-12-2018',
    location: 'Kyiv, Secret Location',
    description: `
      For a long time we've been dreaming that Bibliotheca would feel as relaxed and safe as a space for discovering and sharing as one's living room.\n
      That's why we are turning our actual living room into the library, and goodbye party for Nastya and Hlib — into a special library event. For 24 hours there would be only books & music.\n
      We will feature our 2017/2018 collection of our favorite European publishers, picked by hand at Offprint Paris, London flea markets & Berlin bookstores.\n
      + New arrivals:\n
      - NY Art Book Fair 2018
      - Doomed Gallery (London) book archive
      \n
      Leave your email and we will send you the location.
      Come and see!
    `,
    image: 'https://storage.googleapis.com/bibliotheca-assets/event-bibliotheca-24.jpg',
  },
};
